<template>
  <v-app>
    <v-container>
      <!-- <v-row>
        <div class="w-100">
          <h5 class="pl-3">Tieu de the</h5>

          <div class="px-3">
            <CkContent></CkContent>
          </div>
        </div>
      </v-row> -->
      <v-data-table
        :headers="headers"
        :items="flashcards"
        sort-by="calories"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title><h5>Thẻ học từ vựng</h5></v-toolbar-title>
            <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on"
                  >New Item</v-btn
                >
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-form
                      @submit.prevent="handleUpload"
                      method="post"
                      id="check-login-form"
                    >
                      <v-row>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.word"
                            label="Từ vựng"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.example"
                            label="Ví dụ"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.translate"
                            label="Dịch nghĩa"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-text-field
                            v-model="editedItem.note"
                            label="Ghi chú"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="4">
                          <v-file-input
                            class="pl-1"
                            prepend-icon="mdi-camera"
                            v-model="image"
                            label="Tải ảnh lên"
                            @change="onChangeFileUpload"
                            @click:clear="clearImage"
                          ></v-file-input>
                          <div id="preview">
                            <v-img v-if="cardImg" :src="cardImg" />
                          </div>
                          <!-- <span class="validate-error text-danger">{{ imageError }}</span> -->
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                  <v-btn
                    type="submit"
                    form="check-login-form"
                    color="blue darken-1"
                    text
                    >{{ submitTypeName }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.images="{ item }">
          <img :src="item.images.src" alt width="80" height="120" />
        </template>
        <!-- <template v-slot:no-data>
          <v-btn color="primary" @click="initialize">Reset</v-btn>
        </template>-->
      </v-data-table>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
// import CkContent from "@/components/ck-content";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {
  GET_LIST_FLASHCARD,
  SAVE_FLASHCARD,
  DELETE_FLASHCARD,
  UPDATE_FLASHCARD,
  UPLOAD_FLASHCARD_IMAGE,
} from "@/store/flashcards.module";
import { GET_LESSON_DETAIL } from "@/store/lessons.module";

export default {
  components: {
    // CkContent,
  },
  async mounted() {
    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "Flashcard" }]);
    this.lesson_slug = this.$route.query.lesson_slug;
    await this.$store.dispatch(GET_LESSON_DETAIL, {
      lesson_slug: this.lesson_slug,
    });
    await this.$store.dispatch(GET_LIST_FLASHCARD, { exercise_id: 1 });
  },
  data: () => ({
    dialog: false,
    headers: [
      // { text: "STT", value: "id" },
      { text: "Từ vựng", value: "word" },
      { text: "Ví dụ", value: "example" },
      { text: "Dịch nghĩa", value: "translate" },
      { text: "Ghi chú", value: "note" },
      { text: "Hình ảnh", value: "images", sortable: false },
      { text: "Thao tác", value: "actions", sortable: false },
    ],
    lesson_slug: null,
    editedIndex: -1,
    cardImg: "",
    editedItem: {
      word: "",
      example: "",
      translate: "",
      note: "",
      cardImg: "",
    },
    defaultItem: {
      word: "",
      example: "",
      transalte: "",
      note: "",
      images: {},
    },
    image: {},
    course_id: null,
  }),
  computed: {
    ...mapState({
      flashcards: (state) => state.flashcards.flashcards,
      lesson: (state) => state.lessons.lessonDetail,
    }),
    formTitle() {
      return this.editedIndex === -1 ? "Tạo thẻ mới" : "Sửa thẻ";
    },
    submitTypeName() {
      return this.editedIndex === -1 ? "Save" : "Update";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    onChangeFileUpload() {
      let formData = new FormData();
      formData.append("upload", this.image);
      this.$store
        .dispatch(UPLOAD_FLASHCARD_IMAGE, formData)
        .then((data) => {
          if (data.data.status == 1) {
            this.cardImg = data.data.data;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    handleUpload() {
      // format course data

      this.editedItem.lesson_id = this.lesson.id;
      this.editedItem.cardImg = this.cardImg;
      // add new flashcard
      if (this.editedIndex === -1) {
        this.$store
          .dispatch(SAVE_FLASHCARD, {
            flashcard: this.editedItem,
          })
          .then(() => {
            this.cardImg = "";
            this.image = {};
            this.$store.dispatch(GET_LIST_FLASHCARD, { lesson_id: this.lesson.id });
            this.close();
          });
      }
      if (this.editedIndex !== -1) {
        this.$store
          .dispatch(UPDATE_FLASHCARD, {
            flashcard: this.editedItem,
          })
          .then(() => {
            this.cardImg = "";
            this.image = {};
            this.$store.dispatch(GET_LIST_FLASHCARD, { lesson_id: this.lesson.id });
            this.close();
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.flashcards.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.cardImg = this.editedItem.images.src;
      this.dialog = true;
    },

    deleteItem(item) {
      confirm("Are you sure you want to delete this item?") &&
        this.$store
          .dispatch(DELETE_FLASHCARD, {
            flashcard: item,
          })
          .then(() => {
            this.$store.dispatch(GET_LIST_FLASHCARD, { lesson_id: this.lesson.id });
          });
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    clearImage() {
      this.cardImg = "";
    },
    save() {},
  },
};
</script>
<style>
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-size: 0.87rem !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
}
</style>
